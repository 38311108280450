export default {
  methods: {
    obterDecimais(valor) {
      // console.log(valor, typeof valor);
      if (valor == null) {
        return;
      }
      if (typeof valor == "string") {
        valor = parseFloat(valor);
      }
      // console.log(valor, typeof valor);
      return valor.toFixed(2);
    },

    // Tira caracteres de moeda e formata para enviar ao banco
    retiraReal(valor) {
      // console.log(valor);
      valor = valor.replace("R$", "");
      valor = valor.replace(".", "");
      valor = valor.replace(",", ".");
      valor == 0.0 ? (valor = null) : valor;
      return valor;
    },

    isInt(n) {
      return Number(n) === n && n % 1 === 0;
    },

    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },
  },
};
