<template>
  <v-dialog v-model="mostraAguarde" persistent max-width="400">
    <v-card>
      <v-card-text>
        <div class="text-center" style="font-size: 18px">
          Aguarde, o PDF está sendo gerado...
        </div>
        <div
          class="progress-container d-flex justify-center align-center"
          style="margin: 30px"
        >
          <v-progress-circular
            size="50"
            :width="5"
            :value="aguardeProgress"
            indeterminate
            color="primary"
            style="font-size: 10px"
          ></v-progress-circular>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    mostraAguarde: {
      type: Boolean,
      default: false,
    },
    aguardeProgress: {
      type: Number,
      default: 0,
    },
  },
};
</script>
