import jsPDF from "jspdf";

export default {
  methods: {
    async relCrediResA4Mixin() {
      var doc = new jsPDF("p", "pt", "a4");
      doc.setFont("helvetica");

      doc.setFontSize(10);

      var linha = 0;
      linha += 30;
      doc.text("Resumo das contas abertas", 300, linha, "center");

      var idClienteLoc = this.crediarioSelecionado.idCliente;
      var nomeClienteLoc = this.crediarioSelecionado.nomeCliente;

      linha += 20;
      doc.text("ID: " + idClienteLoc, 10, linha);
      doc.text("Nome: " + nomeClienteLoc, 100, linha);
      doc.text("Data: " + this.mixDataAgoraBR(), 400, linha);
      linha += 5;
      doc.line(5, linha, 585, linha);

      var crediarios = this.crediarios;
      linha += 10;
      var sat = 0;
      doc.setFontSize(8);
      for (var crediario of crediarios) {
        if (idClienteLoc == crediario.idCliente) {
          var crediID = crediario.id;
          var compraDataLoc = this.mixDataDBD(crediario.compraData);
          var compraValorLoc = this.obterDecimais(crediario.compraValor);
          var pagC = crediario.pago;
          var cobC = crediario.cobranca;
          var arqC = crediario.arquivado;

          pagC == 1 ? (pagC = "pago") : (pagC = "");
          cobC == 1 ? (cobC = "cobranca") : (cobC = "");
          arqC == 1 ? (arqC = "arquivado") : (arqC = "");

          doc.setFont("helvetica", "bold");
          doc.text(`Crediario: ${crediID}`, 10, linha);
          doc.text(`Data: ${compraDataLoc}`, 110, linha);
          doc.text(`Valor: ${compraValorLoc}`, 290, linha, "right");
          doc.text(pagC, 460, linha);
          doc.text(cobC, 500, linha);
          doc.text(arqC, 540, linha);
          doc.setFont("helvetica", "normal");

          if (crediario.observacao) {
            doc.setFontSize(7);
            linha += 10;
            doc.text(`Obs.: ${crediario.observacao}`, 10, linha);
            doc.setFontSize(8);
          }

          linha += 12;

          await this.actListarCredParcelas({
            crediario: crediario,
            licenca: this.$store.state.login.licenca,
          });
          var credParce = this.crediarioParcelas;

          doc.setFont("helvetica", "italic");
          doc.text("Parcela", 10, linha);
          doc.text("Vencimento", 50, linha);
          doc.text("Valor", 160, linha, "right");
          doc.text("Atual", 220, linha, "right");
          doc.text("Data Pag", 250, linha);
          doc.text("Val Pag", 380, linha, "right");
          doc.setFont("helvetica", "normal");
          linha += 12;

          var pa = 0;
          var sa = 0;
          for (let i = 0; i < credParce.length; i++) {
            credParce[i].pago == 1
              ? (pa += credParce[i].valorPago)
              : (sa += credParce[i].valorAtual);
            var parce = credParce[i].parcela;
            var dataVenc = this.mixDataDBD(credParce[i].dataVencimento);
            var valParc = this.obterDecimais(credParce[i].valorParcela);
            var valAtu = this.obterDecimais(credParce[i].valorAtual);
            var dataPag = this.mixDataDBD(credParce[i].dataPago);
            var valPag = this.obterDecimais(credParce[i].valorPago);
            var pag = credParce[i].pago;

            valAtu == undefined ? (valAtu = " ") : valAtu;
            valPag == undefined ? (valPag = " ") : valPag;
            valParc == undefined ? (valParc = " ") : valParc;
            pag == 1 ? (pag = "pago") : (pag = "------");

            doc.text(`${parce}`, 10, linha);
            doc.text(`${dataVenc}`, 50, linha);
            doc.text(`${valParc}`, 160, linha, "right");
            doc.text(`${valAtu}`, 220, linha, "right");
            doc.text(`${dataPag}`, 250, linha);
            doc.text(`${valPag}`, 380, linha, "right");
            doc.text(`${pag}`, 450, linha, "right");

            linha += 10;
          }

          sat += sa;
          sa = this.obterDecimais(sa);
          pa = this.obterDecimais(pa);

          doc.text(`Saldo: ${sa}`, 220, linha, "right");
          doc.text(`Pago: ${pa}`, 380, linha, "right");

          linha += 5;
          doc.line(5, linha, 585, linha);
          linha += 10;

          // break;
          if (linha > 800) {
            linha = 40;
            doc.addPage();
          }
        }
      }
      sat = this.obterDecimais(sat);
      doc.setFont("helvetica", "bold");
      doc.text(`Saldo Total: ${sat}`, 220, linha, "right");
      doc.setFont("helvetica", "normal");
      linha += 5;
      doc.line(5, linha, 585, linha);

      doc.output("dataurlnewwindow");
    },
  },
};
