import jsPDF from "jspdf";

export default {
  methods: {
    async relCrediResTermMixin() {
      var doc = new jsPDF("p", "pt", [220, 1200]);
      doc.setFont("helvetica");

      doc.setFontSize(10);

      var linha = 0;
      linha += 30;
      doc.text("Resumo das contas abertas", 110, linha, "center");

      var idClienteLoc = this.crediarioSelecionado.idCliente;
      var nomeClienteLoc = this.crediarioSelecionado.nomeCliente;

      linha += 15;
      doc.text("ID: " + idClienteLoc, 10, linha);
      doc.text("Data: " + this.mixDataAgoraBR(), 80, linha);
      linha += 15;
      doc.text("" + nomeClienteLoc, 10, linha);
      linha += 5;
      doc.line(5, linha, 215, linha);

      var crediarios = this.crediarios;
      linha += 10;
      var sat = 0;
      for (var crediario of crediarios) {
        if (idClienteLoc == crediario.idCliente) {
          var crediID = crediario.id;
          var compraDataLoc = this.mixDataDBD(crediario.compraData);
          var compraValorLoc = this.obterDecimais(crediario.compraValor);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(8);
          doc.text(`${crediID}`, 10, linha);
          doc.text(`Data: ${compraDataLoc}`, 50, linha);
          doc.text(`Valor: ${compraValorLoc}`, 200, linha, "right");
          doc.setFont("helvetica", "normal");

          if (crediario.observacao) {
            doc.setFontSize(7);
            linha += 10;
            doc.text(`Obs.: ${crediario.observacao}`, 10, linha);
            doc.setFontSize(8);
          }

          linha += 15;

          await this.actListarCredParcelas({
            crediario: crediario,
            licenca: this.$store.state.login.licenca,
          });
          var credParce = this.crediarioParcelas;

          doc.setFontSize(8);
          doc.setFont("helvetica", "italic");
          doc.text("P", 10, linha);
          doc.text("Vencimento", 23, linha);
          doc.text("Valor", 95, linha, "right");
          doc.text("Atual", 130, linha, "right");
          doc.text("Data Pag", 138, linha);
          doc.text("Val Pag", 210, linha, "right");

          // doc.text(`Data: ${compraDataLoc}`, 110, linha);
          // doc.text(`Valor: ${compraValorLoc}`, 290, linha, "right");

          doc.setFontSize(8);
          doc.setFont("helvetica", "normal");
          linha += 15;

          var pa = 0;
          var sa = 0;
          for (let i = 0; i < credParce.length; i++) {
            credParce[i].pago == 1
              ? (pa += credParce[i].valorPago)
              : (sa += credParce[i].valorAtual);
            var parce = credParce[i].parcela;
            var dataVenc = this.mixDataDBD(credParce[i].dataVencimento);
            var valParc = this.obterDecimais(credParce[i].valorParcela);
            var valAtu = this.obterDecimais(credParce[i].valorAtual);
            var dataPag = this.mixDataDBD(credParce[i].dataPago);
            var valPag = this.obterDecimais(credParce[i].valorPago);
            var pag = credParce[i].pago;
            // var cob = credParce[i].cobranca;
            // var arq = credParce[i].arquivado;

            valAtu == undefined ? (valAtu = " ") : valAtu;
            valPag == undefined ? (valPag = " ") : valPag;
            valParc == undefined ? (valParc = " ") : valParc;
            dataPag == null ? (dataPag = " ") : dataPag;
            pag == 1 ? (pag = "pago") : (pag = "------");

            doc.text(`${parce}`, 10, linha);
            doc.text(`${dataVenc}`, 23, linha);
            doc.text(`${valParc}`, 95, linha, "right");
            doc.text(`${valAtu}`, 130, linha, "right");
            doc.text(`${dataPag}`, 138, linha);
            doc.text(`${valPag}`, 210, linha, "right");
            // doc.text(`${pag}`, 450, linha, "right");
            // doc.text(`${cob}`, 500, linha, "right");
            // doc.text(`${arq}`, 550, linha, "right");
            linha += 10;
          }

          sat += sa;
          sa = this.obterDecimais(sa);
          pa = this.obterDecimais(pa);

          doc.text(`Saldo: ${sa}`, 130, linha, "right");
          doc.text(`Pago: ${pa}`, 210, linha, "right");
          linha += 5;
          doc.line(5, linha, 215, linha);
          linha += 10;

          // break;
          if (linha > 1000) {
            linha = 30;
            doc.addPage();
          }
        }
      }
      sat = this.obterDecimais(sat);
      doc.setFont("helvetica", "bold");
      doc.text(`Saldo Total: ${sat}`, 130, linha, "right");
      doc.setFont("helvetica", "normal");
      linha += 5;
      doc.line(5, linha, 215, linha);

      doc.output("dataurlnewwindow");
    },
  },
};
