<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg11 xl8>
        <v-data-table
          dense
          :headers="headers"
          :items="crediariosLista"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          class="elevation-10"
          :search="search"
          loading-text="Carregando..."
          sortBy="id"
          sortDesc
          calculate-widths
        >
          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-timer-sand</v-icon>
              <v-toolbar-title>Crediarios da Loja</v-toolbar-title>
              <v-spacer></v-spacer>
              <!-- <v-dialog v-model="dialog" max-width="500px"> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRecarregar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metSelCredParcelaEdicao()"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Novo Crediario</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metAbrirDialog()"
                  >
                    mdi-printer-outline
                  </v-icon>
                </template>
                <span>Relatórios</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
              <!-- </v-dialog> -->
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col
                  cols="auto"
                  md="4"
                  class="ml-1 mb-0"
                  align-self="end"
                  v-if="metMudaBusca()"
                >
                  <v-text-field
                    v-model="search"
                    v-if="metMudaBusca()"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                    clearable
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="auto"
                  md="4"
                  class="ml-1 mb-0"
                  align-self="end"
                  v-if="!metMudaBusca()"
                >
                  <v-autocomplete
                    v-if="!metMudaBusca()"
                    v-model="idCli"
                    hide-details
                    single-line
                    append-icon="mdi-magnify"
                    label="Busca"
                    :items="clienteLista"
                    item-text="nome_cliente"
                    item-value="id_cliente"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="ml-2" align-self="end">
                  <v-chip-group
                    active-class="primary"
                    show-arrows
                    v-model="varSelectLista"
                    mandatory
                  >
                    <v-chip
                      key="abertos"
                      value="abertos"
                      @click="metMudaLista('abertos')"
                    >
                      Abertos
                    </v-chip>
                    <v-chip
                      key="cobranca"
                      value="cobranca"
                      @click="metMudaLista('cobranca')"
                    >
                      Cobrança
                    </v-chip>
                    <v-chip
                      key="arquivados"
                      value="arquivados"
                      @click="metMudaLista('arquivados')"
                    >
                      Arquivados
                    </v-chip>
                    <v-chip
                      key="especifico"
                      value="especifico"
                      @click="metMudaLista('especifico')"
                    >
                      Especifico
                    </v-chip>
                    <v-chip
                      key="todos"
                      value="todos"
                      @click="metMudaLista('todos')"
                    >
                      Todos
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:[`item.idConvenio`]="{ item }">
            <span>{{ obterConveniada(item.idConvenio) }} </span>
          </template>
          <template v-slot:[`item.compraData`]="{ item }">
            <span>{{ mixDataDBD(item.compraData) }} </span>
          </template>
          <template v-slot:[`item.compraValor`]="{ item }">
            <span>{{ obterDecimais(item.compraValor) }} </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-1"
                  color="blue darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSelCredParcelaEdicao(item)"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-1"
                  color="green darken-3"
                  v-bind="attrs"
                  v-on="on"
                  key="especifico"
                  value="especifico"
                  @click="metListaCredEspecifico(item.idCliente)"
                  v-if="varMostraIconEsp"
                >
                  mdi-account-search
                </v-icon>
              </template>
              <span>Cliente</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-1"
                  color="indigo darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metImpCredResumoTerm(item)"
                  v-if="varMostraPrinterTerm"
                >
                  > mdi-printer
                </v-icon>
              </template>
              <span>Resumo Térmica</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-5"
                  color="purple darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metImpCredResumoA4(item)"
                >
                  mdi-printer-outline
                </v-icon>
              </template>
              <span>Resumo A4</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  color="blue darken-1"
                  v-bind="attrs"
                  v-on="on"
                  v-if="!item.pago"
                >
                  mdi-av-timer
                </v-icon>
              </template>
              <span>Aberto</span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  v-if="item.cobranca"
                >
                  mdi-wallet
                </v-icon>
              </template>
              <span>Cobrança</span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  v-if="item.pago"
                >
                  mdi-cash-register
                </v-icon>
              </template>
              <span>Pago</span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  color="brown"
                  v-bind="attrs"
                  v-on="on"
                  v-if="item.arquivado"
                >
                  mdi-archive
                </v-icon>
              </template>
              <span>Arquivado</span>
            </v-tooltip>
            <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-dialog v-model="varMostraDialog" max-width="500">
      <v-card>
        <v-card-title>Escolha um relatório:</v-card-title>
        <v-card-text>
          <v-select
            v-model="varSelcionarRelatorio"
            :items="varRelatorios"
            label="Relatórios"
            item-value="value"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="metEscolhidoRelatorio(varSelcionarRelatorio)"
            color="primary"
            >Gerar Relatório</v-btn
          >
          <v-btn @click="varMostraDialog = false" color="secondary"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <aguarde-dialog
      :mostraAguarde="varMostraAguarde"
      :aguardeProgress="varAguardeProgress"
    />
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
import ConfirmDialogue from "@/components/ConfirmDialogue.vue";
import DatasMixin from "@/mixins/DatasMixin";
import MoedasMixin from "@/mixins/MoedasMixin";
import RelCredResTermMixin from "../_mixins/relCredResTermMixin";
import RelCredResA4Mixin from "../_mixins/relCredResA4Mixin";
import RelCredCompletoMixin from "../_mixins/relCredCompletoMixin";
import axios from "../_services/axios";
import AguardeDialog from "@/components/AguardeDialog";

const { mapGetters, mapState, mapActions } =
  createNamespacedHelpers("lojaCrediarios");

export default {
  components: { ConfirmDialogue, AguardeDialog },
  mixins: [
    DatasMixin,
    MoedasMixin,
    RelCredResTermMixin,
    RelCredResA4Mixin,
    RelCredCompletoMixin,
  ],
  name: "LojaCrediariosLista",
  data: () => ({
    listaCliAtivos: "ativos",
    search: "",
    dialog: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      { text: "Código", value: "id" },
      { text: "Cliente", value: "nomeCliente", align: "start" },
      { text: "Compra", value: "compraData", align: "start" },
      { text: "Valor", value: "compraValor", align: "end" },
      { text: "Convenio", value: "idConvenio", align: "start" },
      { text: "Ações", value: "actions", sortable: false },
    ],
    varSelectLista: undefined,
    idCli: [],
    varMostraPrinterTerm: true,
    varMostraIconEsp: true,
    varMostraDialog: false,
    varSelcionarRelatorio: null,
    varRelatorios: [
      { value: "metImpCredAbertos", text: "Relatório Abertos" },
      { value: "metImprimeCredCob", text: "Relatório Cobrança" },
    ],
    varMostraAguarde: false,
    varAguardeProgress: 0,
  }),

  computed: {
    ...mapState([
      "erro",
      "crediarios",
      "crediarioSelecionado",
      "clientesSelect",
      "conveniadasSelect",
      "staListaCred",
      "crediarioParcelas",
      "staIdCliente",
    ]),
    ...mapGetters(["crediClientePrint"]),

    crediariosLista() {
      return this.crediarios;
    },

    clienteLista() {
      return this.clientesSelect;
    },
  },

  created() {
    register(this.$store);
    this.varSelectLista = this.staListaCred;
    this.actResetCredSelecionado();
    switch (this.staListaCred) {
      case "abertos":
        this.actListaCredAbertos(this.$store.state.login.licenca);
        break;
      case "cobranca":
        this.actListaCredCobranca(this.$store.state.login.licenca);
        break;
      case "arquivados":
        this.actListaCredArquivados(this.$store.state.login.licenca);
        break;
      case "especifico":
        this.actListaCredEspecifico({
          id: this.staIdCliente,
          licenca: this.$store.state.login.licenca,
        });
        break;
      case "todos":
    }
    this.actSelectClientes(this.$store.state.login.licenca);
    this.actSelectConveniadas(this.$store.state.login.licenca);
    this.metMostraIconsAcao();
  },

  watch: {
    erro() {
      if (this.erro) {
        this.$root.snackbar.show({
          type: "error",
          message: this.erro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
    idCli() {
      // console.log(this.idCli);
      this.actCrediarioClientesID({
        id: this.idCli,
        licenca: this.$store.state.login.licenca,
      });
    },
  },

  mounted() {
    //this.obterCrediarios();
  },

  methods: {
    ...mapActions([
      "actListaCredAbertos",
      "actListaCredCobranca",
      "actListaCredArquivados",
      "actListaCredEspecifico",
      "actSelectCrediario",
      "actResetCredSelecionado",
      "actAtivarCrediario",
      "actSelectClientes",
      "actSelectConveniadas",
      "actMudarModoListaCred",
      "actLimparListaCrediarios",
      "actCrediarioClientesID",
      "actListarCredParcelas",
      "actCompletoPDF",
      "actMudarIdCliente",
    ]),

    metEscolhidoRelatorio(selectedOption) {
      console.log(selectedOption);
      if (selectedOption !== null) {
        const methodToExecute = selectedOption;
        if (
          this[methodToExecute] &&
          typeof this[methodToExecute] === "function"
        ) {
          this[methodToExecute](); // Chama o método selecionado
        }
        // Fechar o diálogo após a execução do método
        this.varMostraDialog = false;
      }
    },

    async metAbrirDialog() {
      this.varMostraDialog = true;
    },

    metListaCredEspecifico(idCli) {
      // console.log(idCli);
      this.varMostraIconEsp = false;
      this.actMudarIdCliente(idCli);
      this.metMudaLista("especifico");
      this.actListaCredEspecifico({
        id: this.staIdCliente,
        licenca: this.$store.state.login.licenca,
      });
    },

    metMudaLista(modo) {
      // console.log(modo);
      this.actMudarModoListaCred({ modo: modo });
      this.metMostraIconsAcao();
      this.metRecarregar();
    },

    metMudaBusca() {
      this.varSelectLista = this.staListaCred;
      switch (this.staListaCred) {
        case "abertos":
          return true;
        case "cobranca":
          return true;
        case "arquivados":
          return true;
        case "especifico":
          return true;
        case "todos":
          return false;
      }
    },

    metMostraIconsAcao() {
      switch (this.staListaCred) {
        case "abertos":
          this.varMostraPrinterTerm = true;
          this.varMostraIconEsp = true;
          break;
        case "cobranca":
          this.varMostraPrinterTerm = false;
          this.varMostraIconEsp = true;
          break;
        case "arquivados":
          this.varMostraPrinterTerm = false;
          this.varMostraIconEsp = true;
          break;
        case "especifico":
          this.varMostraPrinterTerm = true;
          this.varMostraIconEsp = false;
          break;
        case "todos":
          this.varMostraPrinterTerm = false;
      }
    },

    obterConveniada(id) {
      if (id != null && this.conveniadasSelect.length != 0) {
        const ret = this.conveniadasSelect.filter((c) => c.id == id);
        return ret[0].alias;
      } else {
        const ret = "";
        return ret;
      }
    },

    ativoColor(ativo) {
      if (ativo) return "green darken-1";
      else return "red darken-1";
    },

    initialize() {
      /*       if (this.crediarios === null) {
        this.crediarios = null;
      } */
    },
    login() {
      this.$router.push({ name: "Login" });
    },
    novoCrediario() {
      if (this.crediarioSelecionado) {
        this.actResetCredSelecionado();
        return;
      }
    },
    metSelCredParcelaEdicao(crediario) {
      this.actSelectCrediario({ crediario });
      this.$router.push({ name: "LojaCrediariosParcelasForm" });
    },

    async metImpCredResumoTerm(crediario) {
      this.varMostraAguarde = true;
      await this.actSelectCrediario({ crediario });
      await this.relCrediResTermMixin();
      this.varMostraAguarde = false;
    },
    async metImpCredResumoA4(crediario) {
      this.varMostraAguarde = true;
      await this.actSelectCrediario({ crediario });
      await this.relCrediResA4Mixin();
      this.varMostraAguarde = false;
    },

    metImpCredAbertos() {
      this.metImpCrediarios("abertos");
    },

    metImprimeCredCob() {
      this.metImpCrediarios("cobranca");
    },

    async metImpCrediarios(tipoCred) {
      this.varMostraAguarde = true;

      const response = await axios.getCompletoPDF(
        tipoCred,
        this.$store.state.login.licenca
      );
      var byteCharacters = response.data.completoPDF;
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: "application/pdf;base64" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.varMostraAguarde = false;
    },

    actSelectCrediarioEdicao(crediario) {
      this.actSelectCrediario({ crediario });

      this.$router.push({ name: "LojaCrediariosForm" });
    },

    paraAtivacao(crediario) {
      // if (crediario.data_cadastro != "0000-00-00") {
      crediario.data_cadastro = this.formataDataIn(crediario.data_cadastro);
      // }
      // if (crediario.ultima_compra != "0000-00-00") {
      crediario.ultima_compra = this.formataDataIn(crediario.ultima_compra);
      // }
      this.actAtivarCrediario({ crediario });
      // this.actListaCredAbertos();
    },
    // actResetCredSelecionado() {
    //   this.actResetCredSelecionado();
    // },
    metVoltar() {
      this.$router.back();
    },
    metRecarregar() {
      switch (this.staListaCred) {
        case "abertos":
          this.actListaCredAbertos(this.$store.state.login.licenca);
          break;
        case "cobranca":
          this.actListaCredCobranca(this.$store.state.login.licenca);
          break;
        case "arquivados":
          this.actListaCredArquivados(this.$store.state.login.licenca);
          break;
        case "especifico":
          this.actListaCredEspecifico({
            id: this.staIdCliente,
            licenca: this.$store.state.login.licenca,
          });
          break;
        case "todos":
          this.actLimparListaCrediarios();
      }
    },
  },
};
</script>
